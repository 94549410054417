import Styled from '@emotion/styled';
import Header from '../components/Header';
import Image from '../components/Image';

const StyledApproach = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const Banner = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 800px;
`;

const imageUrl =
  'https://img.freepik.com/premium-photo/network-server-room-with-blinking-lights_1272857-54321.jpg?w=1380';

const Approach = () => (
  <div>
    <Header />
    <StyledApproach>
      <Banner>
        <Image
          src={imageUrl}
          label="Network Server Room with blinking lights"
          fit="cover"
        />
      </Banner>
    </StyledApproach>
  </div>
);

export default Approach;
