import Styled from '@emotion/styled';
import Header from '../components/Header';

const StyledTeam = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;
const Team = () => (
  <div>
    <Header />
    <StyledTeam>Team</StyledTeam>
  </div>
);

export default Team;
