import Styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const backgroundAnimation = keyframes`
  0% {
    background-position: 50% 0%; 
  }
  25% {
    background-position: 50% 25%; 
  }
  50% {
    background-position: 50% 50%; 
  }
  75% {
    background-position: 50% 75%; 
  }
  100% {
    background-position: 50% 100%; 
  }
`;

const StyledLanding = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-weight: 800;
  background: linear-gradient(180deg, ${({ theme }) => theme.colors.darkBlue}, ${({ theme }) => theme.colors.black}, ${({ theme }) => theme.colors.darkBlue});
  background-size: 600% 600%; 
  animation: ${backgroundAnimation} 10s ease-in-out forwards; 
`;

const Title = Styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  font-weight: 800;
  padding: 2rem 0;
`;

const Tiger = Styled.span`
  color: ${({ theme }) => theme.colors.greenAccent}; 
`;

const DC = Styled.span`
  color: ${({ theme }) => theme.colors.white};
`;

const Subtitle = Styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.silver};
`;

const Landing = () => (
  <div>
    <StyledLanding>
      <Title>
        <Tiger>Tiger</Tiger>
        <DC>DC</DC>
      </Title>
      <Subtitle>Coming Soon</Subtitle>
    </StyledLanding>
  </div>
);

export default Landing;
