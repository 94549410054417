import Styled from '@emotion/styled';
import Header from '../components/Header';
import ImageSlider from '../components/ImageSlider';

const StyledCooling = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const images = [
  {
    url: 'https://via.placeholder.com/600x400?text=Image+1',
    title: 'Image 1',
  },
  {
    url: 'https://via.placeholder.com/600x400?text=Image+2',
    title: '',
  },
  {
    url: 'https://via.placeholder.com/600x400?text=Image+3',
    title: 'Im a really long description of this picture',
  },
  {
    url: 'https://via.placeholder.com/600x400?text=Image+4',
    title: 'Image 4',
  },
  {
    url: 'https://via.placeholder.com/600x400?text=Image+5',
    title: 'Image 5',
  },
  {
    url: 'https://via.placeholder.com/600x400?text=Image+6',
    title: 'Image 6',
  },
  {
    url: 'https://via.placeholder.com/600x400?text=Image+7',
    title: 'Image 7',
  },
  {
    url: 'https://via.placeholder.com/600x400?text=Image+8',
    title: 'Image 8',
  },
  {
    url: 'https://via.placeholder.com/600x400?text=Image+9',
    title: 'Image 9',
  },
  {
    url: 'https://via.placeholder.com/600x400?text=Image+10',
    title: 'Image 10',
  },
  {
    url: 'https://via.placeholder.com/600x400?text=Image+11',
    title: 'Image 11',
  },
  {
    url: 'https://via.placeholder.com/600x400?text=Image+12',
    title: 'Image 12',
  },
];

const Cooling = () => (
  <div>
    <Header />
    <StyledCooling>
      <ImageSlider images={images} showDots={true} />
    </StyledCooling>
  </div>
);

export default Cooling;
