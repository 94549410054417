import Styled from '@emotion/styled';
import GoogleMap from '../components/Map';
import Header from '../components/Header';

const StyledDataCenters = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;
const MapWrapper = Styled.div`
  width:80%;
  height: 300px; 

  @media (min-width: 768px) {
    width: 900px;
    height: 500px;
    max-width: 100%;
  }
`;

const DataCenters = () => (
  <div>
    <Header />
    <StyledDataCenters>
      <MapWrapper>
        <GoogleMap address="1600 Amphitheatre Parkway, Mountain View, CA" />
      </MapWrapper>
    </StyledDataCenters>
  </div>
);

export default DataCenters;
