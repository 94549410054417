import Styled from '@emotion/styled';
import Header from '../components/Header';

const StyledPartners = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;
const Partners = () => (
  <div>
    <Header />
    <StyledPartners>Partners</StyledPartners>
  </div>
);

export default Partners;
