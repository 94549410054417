const theme = {
  colors: {
    white: '#fff',
    black: '#000',
    darkBlue: '#1A3355',
    lightBlue: '#6E98C9',
    silver: '#A0A0A0',
    slate: '#8996A7',
    charcoal: '#484848',
    greenAccent: '#4CAF50',

    background: '#FFFFFF',
    text: '#727272',

    activeDot: 'rgba(255, 255, 255, 1)',
    inactiveDot: 'rgba(255, 255, 255, 0.6)',
    overlay: 'rgba(0, 0, 0, 0.9)',
  },
  fonts: {
    primary: "'Barlow', sans-serif",
    secondary: "'Inter', sans-serif",
  },
  fontSizes: {
    small: '1.2rem',
    medium: '2rem',
    large: '4rem',
    xlarge: '8rem',
    xxlarge: '12.8rem',
  },
};

export default theme;
