import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import NavMenu from './NavMenu';

const HeaderStyled = Styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 1rem;
    border-bottom: 1px solid #ddd;

    @media (min-width: 768px) {
        padding: 0 2rem;
    }
`;

const Logo = Styled.div`
    font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const HamburgerMenu = Styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 18px;
    cursor: pointer;
    font-size: ${({ theme }) => theme.fontSizes.medium};

    div {
        width: 100%;
        height: 3px;
        background-color: ${({ theme }) => theme.colors.black};
    }

    @media (min-width: 768px) {
        display: none;
    }
`;

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <HeaderStyled>
      <Logo>
        <Link to="/">TigerDC</Link>
      </Logo>
      <HamburgerMenu onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} />
      </HamburgerMenu>
      <NavMenu isOpen={menuOpen} />
    </HeaderStyled>
  );
};

export default Header;
