import React from 'react';
import PropTypes from 'prop-types';
import Styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const NavLinks = Styled.nav`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: 1rem;
  padding: 1rem;
  border: none;
  gap: 1rem;
  z-index: 100;

  a {
    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: ${({ theme }) => theme.fontSizes.medium};
    text-decoration: none;
  }

  @media (min-width: 768px) {
    display: flex;
    position: static;
    flex-direction: row;
    gap: 2rem;
    background-color: transparent;
    border: none;

    a {
      font-size: ${({ theme }) => theme.fontSizes.medium};
    }
  }
`;

const NavMenu = ({ isOpen }) => {
  return (
    <NavLinks isOpen={isOpen}>
      <Link to="/datacenters">Data Centers</Link>
      <Link to="/approach">Approach</Link>
      <Link to="/cooling">Cooling</Link>
      <Link to="/team">Team</Link>
      <Link to="/partners">Partners</Link>
    </NavLinks>
  );
};

NavMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default NavMenu;
