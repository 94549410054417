import Styled from '@emotion/styled';
import Header from '../components/Header';

const StyledNotFound = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;
const NotFound = () => (
  <div>
    <Header />
    <StyledNotFound>
      <h1>404 - Not Found</h1>
    </StyledNotFound>
  </div>
);

export default NotFound;
