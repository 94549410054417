import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faExpand,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useSwipeable } from 'react-swipeable';
import FullscreenOverlay from './FullscreenOverlay';
import ImageDots from './ImageDots';

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: ${({ width }) => width};
  aspect-ratio: ${({ width, height }) =>
    `${parseInt(width)} / ${parseInt(height)}`};
  margin: auto;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
`;

const Arrows = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 10;
  pointer-events: none;
`;

const ArrowButton = styled.button`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  border: none;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 0 10px;
  margin: 10px;
  opacity: 0.7;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  pointer-events: auto;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const FullscreenButton = styled.button`
  position: absolute;
  top: 10px;
  right: 0px;
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.background};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  z-index: 10;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  @media (min-width: 768px) {
    top: 20px;
    right: 20px;
  }
`;

const Title = styled.div`
  position: absolute;
  bottom: 45px;
  left: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: ${({ theme }) => theme.colors.white};
  padding: 5px 10px;
  border-radius: 5px;
  max-width: 80%;
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (min-width: 768px) {
    bottom: 15px;
  }
`;

const InfoIcon = styled.div`
  position: absolute;
  bottom: 20px;
  left: 10px;
  background: ${({ theme }) => theme.colors.lightBlue};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    display: none;
  }
`;

const TitleInfo = styled.div`
  position: absolute;
  bottom: 45px;
  left: 10px;
  background: rgba(0, 0, 0, 0.4);
  color: ${({ theme }) => theme.colors.white};
  padding: 5px 10px;
  border-radius: 5px;
  max-width: 80%;
  font-size: ${({ theme }) => theme.fontSizes.small};
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};

  @media (min-width: 768px) {
    display: none;
  }
`;

const ImageSlider = ({
  images,
  showDots = true,
  width = '600px',
  height = '400px',
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showTitleInfo, setShowTitleInfo] = useState(false);

  const nextSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : prevIndex) // Prevent wrapping around
    );
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex) // Prevent wrapping around
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isHovered) return;

      if (event.key === 'ArrowRight') {
        nextSlide();
      } else if (event.key === 'ArrowLeft') {
        prevSlide();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isHovered, nextSlide, prevSlide]);

  const handleInfoClick = () => {
    setShowTitleInfo(true);
    setTimeout(() => {
      setShowTitleInfo(false);
    }, 2000);
  };

  return (
    <SliderContainer
      {...handlers}
      width={width}
      height={height}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Arrows>
        <ArrowButton onClick={prevSlide}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </ArrowButton>
        <ArrowButton onClick={nextSlide}>
          <FontAwesomeIcon icon={faChevronRight} />
        </ArrowButton>
      </Arrows>
      <Image
        src={images[currentIndex].url}
        alt={`Slide ${currentIndex + 1}`}
        onClick={toggleFullscreen}
      />
      {images[currentIndex].title && window.innerWidth > 768 && (
        <Title>
          {images[currentIndex].title.length > 32
            ? `${images[currentIndex].title.substring(0, 29)}...`
            : images[currentIndex].title}
        </Title>
      )}
      {images[currentIndex].title && window.innerWidth <= 768 && (
        <>
          <InfoIcon onClick={handleInfoClick}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </InfoIcon>
          <TitleInfo isVisible={showTitleInfo}>
            {images[currentIndex].title}
          </TitleInfo>
        </>
      )}
      {showDots && (
        <ImageDots
          images={images.map((image) => image.url)} // Map to URLs
          currentIndex={currentIndex}
          goToSlide={goToSlide}
          position="bottom"
        />
      )}
      <FullscreenButton onClick={toggleFullscreen}>
        <FontAwesomeIcon icon={faExpand} />
      </FullscreenButton>
      {isFullscreen && (
        <FullscreenOverlay
          image={images[currentIndex].url}
          imageTitle={images[currentIndex].title}
          images={images.map((img) => img.url)}
          currentIndex={currentIndex}
          onClose={toggleFullscreen}
          onPrev={prevSlide}
          onNext={nextSlide}
          goToSlide={goToSlide}
        />
      )}
    </SliderContainer>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  showDots: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default ImageSlider;
