import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import useScrollLock from '../../hooks/useScrollLock';
import ImageDots from './ImageDots';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.overlay};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 0px;
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.background};
  font-size: ${({ theme }) => theme.fontSizes.large};

  @media (min-width: 768px) {
    top: 20px;
    right: 20px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-height: 90vh;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
`;

const FullscreenImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;

  @media (min-width: 768px) {
    width: 80%;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  min-height: 50px;
  width: 100%;
`;

const ImageTitle = styled.h2`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: normal;
  line-height: 1.2;

  @media (min-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;

const Arrows = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 10;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 0 10px;
  opacity: 0.7;
  background: ${({ theme }) => theme.colors.text};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 20px;
  display: block;

  @media (max-width: 768px) {
    display: none;
  }

  &:hover {
    opacity: 1;
  }
`;

const FullscreenOverlay = ({
  image,
  imageTitle,
  images,
  currentIndex,
  onClose,
  onPrev,
  onNext,
  goToSlide,
}) => {
  // Lock scroll on fullscreen overlay
  useScrollLock(true);

  return (
    <Overlay>
      <CloseButton onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </CloseButton>
      <Arrows>
        <ArrowButton onClick={onPrev}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </ArrowButton>
        <ArrowButton onClick={onNext}>
          <FontAwesomeIcon icon={faChevronRight} />
        </ArrowButton>
      </Arrows>
      <ImageContainer>
        <FullscreenImage src={image} alt="Fullscreen" />
        <TitleWrapper>
          {imageTitle && <ImageTitle>{imageTitle}</ImageTitle>}
        </TitleWrapper>
      </ImageContainer>
      <ImageDots
        images={images}
        currentIndex={currentIndex}
        goToSlide={goToSlide}
      />
    </Overlay>
  );
};

FullscreenOverlay.propTypes = {
  image: PropTypes.string.isRequired,
  imageTitle: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentIndex: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  goToSlide: PropTypes.func.isRequired,
};

export default FullscreenOverlay;
