import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import theme from './styles/theme';
import Landing from './pages/Landing';
import DataCenters from './pages/DataCenters';
import Approach from './pages/Approach';
import Cooling from './pages/Cooling';
import Team from './pages/Team';
import Partners from './pages/Partners';
import NotFound from './pages/NotFound';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/team" element={<Team />} />
          <Route path="/approach" element={<Approach />} />
          <Route path="/datacenters" element={<DataCenters />} />
          <Route path="/cooling" element={<Cooling />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
