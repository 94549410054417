import React, { useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border: none;
  * {
    border: none !important;
  }
`;

const GoogleMap = ({ address }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const loadMap = async () => {
      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        version: 'weekly',
        libraries: ['marker'],
      });

      const google = await loader.load();

      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK') {
          const map = new google.maps.Map(mapRef.current, {
            center: results[0].geometry.location,
            zoom: 14,
            fullscreenControl: true,
            zoomControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            rotateControl: false,
            scaleControl: false,
            mapId: 'tiger-dc-map',
          });

          new google.maps.marker.AdvancedMarkerElement({
            map: map,
            position: results[0].geometry.location,
            title: address,
          });
        } else {
          // eslint-disable-next-line no-console
          console.error(
            `Geocode was not successful for the following reason: ${status}`
          );
        }
      });
    };

    loadMap();
  }, [address]);

  return <MapContainer ref={mapRef} />;
};

GoogleMap.propTypes = {
  address: PropTypes.string.isRequired,
};

export default GoogleMap;
